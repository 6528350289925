.snake_error {
  display: none;
}

.snake_container {
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.game_nav_btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media screen and (max-width: 1300px) {
  .snake_page_container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .snake_error {
    display: block;
    color: red;
  }

  .snake_container {
    display: none;
  }
}