.introtext {
    margin-left: 5%;
    position: relative;
    margin-bottom: 3rem;
}

.introtext::after {
    content: " ";
    position: absolute;
    border-bottom: 10px solid white;
    width: 50%;
    height: 0.2rem;
}

.certifications_grid {
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-bottom: 5%;
}

.certification {
    display: grid;
    background: var(--color-bg-variant);
    padding: 1.3rem;
    max-height: 27rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.certification:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.item_title_text {
    text-align: center;
    color: var(--color-bg-primary);
    font-size: 1.5rem;
    padding-bottom: 2rem;
}

.icon {
    background: transparent;
    padding: 0.1rem;
    display: flex;
    color: var(--color-bg-primary);
    font-size: 1.2rem;
    justify-content: center;
}

.icon:hover {
    color: white;
}

.grid_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.date {
    align-self: flex-end;
}

.c_btn {
    align-self: flex-end;
    justify-self: end;
    max-height: 3rem;
}

.cert_image {
  width: 200px;
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* Mobile Devices */
@media screen and (max-width: 600px) {
    .certifications_grid {
        grid-template-columns: 1fr;
        margin-top: 48px;
    }

    .icon {
      margin-bottom: 32px;
    }
}