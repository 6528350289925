.home_btn {
  margin: 0% 50%;
}

.games.content {
  display: flex;
  flex-direction: row;
  gap: 10rem;
  justify-content: center;
}

#games .languages {
  grid-template-columns: 1fr;
}

#games .details h3 {
  font-size: 3rem;
}

.icon_flex_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gap {
  height: 10vh;
}

@media screen and (max-width: 1300px){
  .games.content {
    display: flex;
    flex-direction: column;
    gap: 10rem;
    justify-content: center;
  }
}