/* Introduction Text */
.introtext {
    margin-left: 5%;
    height: fit-content;
    position: relative;
    margin-bottom: 3rem;
}

.introtext::after {
    content: " ";
    position: absolute;
    border-bottom: 10px solid white;
    width: 50%;
    height: 0.2rem;
}

/* Grid for experience items */
.experience_grid {
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-bottom: 5%;
}

/* Specific item in experience grid */
.experience_item {
    display: grid;
    background: var(--color-bg-variant);
    padding: 1.3rem;
    height: 100%;
    max-height: 27rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience_item:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.item_title_text {
    text-align: center;
    color: var(--color-bg-primary);
    font-size: 1.5rem;
    padding-bottom: 2rem;
}

.icon {
    background: transparent;
    padding: 0.1rem;
    display: flex;
    color: var(--color-bg-primary);
    font-size: 1.2rem;
    justify-content: center;
}

.icon:hover {
    color: white;
}

/* Grid for role, date, and responsibilities button */
.full_grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Button to open responsibilities button */
.r_btn {
    justify-self: end;
    align-self: flex-end;
    max-height: 3rem;
}

/* List for responsibilites */
.r_list {
    margin-left: 10px;
    margin-top: 5px;
}

/* Specific session organizer */
.line {
    content: " ";
    border-bottom: 1px solid white;
    width: 50%;
}

.session_name {
    font-size: 1.2rem;
}

/* Pop up window */
.pop_up_window {
    max-width: 25vw;
    justify-self: center;

    max-height: 90vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

/* Style for the modal content */
.pop_up_content {
    overflow-y: auto;
    flex: 1;
    padding: 20px;
    border-color: black;
    border: 10px;
}

/* Style for close button for popup */
.pop_up_actions{
    border-top: 1px solid gray;
}

/* When the modal is open, prevent body from scrolling */
body.modal-open {
    overflow: hidden;
}

/* Mobile Devices */
@media screen and (max-width: 600px) {
    .experience_grid {
        display: flex;
        flex-direction: column;
    }

    .experience_item {
      max-height: fit-content;
    }

    .full_grid {
      flex-direction: column;
    }

    .full_grid * {
      padding: 10px 0;
    }

    .info_grid {
        text-align: center;
    }

    .r_btn {
        justify-self: center;
        align-self: center;
    }

    .pop_up_window {
        max-width: 100vw;
        max-height: 75vh;
    }
}