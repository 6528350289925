/* General header style */
header {
    height: fit-content;
    overflow: hidden;
    position: relative;
    padding: 5rem 0;
}

/* Container that most of the section, excluding particles */
.header__container {
    text-align: center;
    position: relative;
}

/* Links */
.links {
    margin-top: 1.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* Socials */
.socials {
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.socials::before {
    content: '';
    width: 2rem;
    height: 1px;
    background: var(--color-bg-primary);
}

.socials::after {
    content: '';
    width: 2rem;
    height: 1px;
    background: var(--color-bg-primary);
}

/* Image of myself */
.me {
    max-width: 28rem;
    margin-left: auto;
    margin-right: auto;
}

/* Particles effect */
.particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Medium Devices */
@media screen and (max-width: 1024px) {
}

/* Small Devices */
@media screen and (max-width: 600px) {
}