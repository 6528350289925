.introtext {
    margin-left: 5%;
    height: fit-content;
    position: relative;
    margin-bottom: 3rem;
}

.introtext::after {
    content: " ";
    position: absolute;
    border-bottom: 10px solid white;
    width: 50%;
    height: 0.2rem;
}

.contact__options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0% 5%;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1rem;
}

/* Medium Devices */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        width: 65%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* Small Devices */
@media screen and (max-width: 600px) {
    .contact__options {
        grid-template-columns: 1fr;
    }

    .contact__option {
        width: 100%;
        padding: 1.2rem 0rem;
    }
}